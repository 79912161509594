<template>
  <div class="message-details">
    <div class="action-buttons">
      <div class="control is-grouped">
        <a class="button is-small"><i class="fa fa-inbox"></i></a>
        <a class="button is-small"><i class="fa fa-exclamation-circle"></i></a>
        <a class="button is-small"><i class="fa fa-trash-o"></i></a>
      </div>
      <div class="control is-grouped">
        <a class="button is-small"><i class="fa fa-exclamation-circle"></i></a>
        <a class="button is-small"><i class="fa fa-trash-o"></i></a>
      </div>
      <div class="control is-grouped">
        <a class="button is-small"><i class="fa fa-folder"></i></a>
        <a class="button is-small"><i class="fa fa-tag"></i></a>
      </div>
    </div>

    <!--  

    <div class="box message-preview">
      <div class="top">
      
        

        <div class="avatar">
          <img src="https://placehold.it/128x128" />
        </div>
        <div class="address">
          <div class="name">John Smith</div>
          <div class="email">someone@gmail.com</div>
        </div>
        <hr />
        <div class="content"></div>
      </div>
    </div>

 -->

    <div class="card" style="margin-top: 20px">
      <!-- 
      <div class="card-image">
        <figure class="image is-4by3">
          <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
        </figure>
      </div>
        -->
      <div class="card-content">
        <div class="media">
          <div class="media-left">
            <figure class="image is-48x48">
              <avatar :fullname="parsed.from.name" :size="48" style="float: left; margin-right: 20px" />
              <!--  
              <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" /> -->
            </figure>
          </div>
          <div class="media-content">
            <p class="title is-5">{{ parsed.from.name || parsed.from.address }}</p>
            <p class="subtitle is-6" v-if="parsed.from.name">{{ parsed.from.address }}</p>
          </div>
        </div>

        <div class="content">
          <h3 class="title is-4">{{ parsed.subject }}</h3>
          <div class="content" v-html="parsed.html || parsed.text"></div>

          <!-- 
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec iaculis mauris. <a>@bulmaio</a>.
          <a href="#">#css</a> <a href="#">#responsive</a>
          <br /> 
          <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
          -->
          <hr />
        </div>
      </div>

      <footer class="card-footer">
        <a href="#" class="card-footer-item">Star</a>
        <a href="#" class="card-footer-item">Junk</a>
        <a href="#" class="card-footer-item">Delete</a>
      </footer>
    </div>

    <!-- RRR@333-->

    55555888

    <div class="box message-details">
      <b-card header-tag="header" footer-tag="footer">
        <header>
          <div class="title is-4">{{ parsed.headers.subject || "No subject" }}</div>
          <div class="subtitle is-6">{{ parsed.headers.from }}</div>
        </header>

        <div>
          <p style="white-space: pre-line">{{ parsed.text }}</p>
        </div>

        <footer>
          <div class="tags">
            <span class="tag">{{ parsed.headers.date }}</span>
            <span class="tag">{{ parsed.headers["message-id"] }}</span>
          </div>
          <b-button @click="showDetails = !showDetails">
            {{ showDetails ? "Hide details" : "View details" }}
          </b-button>
          <div v-if="showDetails" class="details">
            <h3 class="title is-5">HTML Version</h3>
            <div class="content" v-html="parsed.html"></div>
            <h3 class="title is-5">Additional Metadata</h3>
            <ul class="metadata">
              <li><strong>To:</strong> {{ parsed.headers.to }}</li>
              <li><strong>CC:</strong> {{ parsed.headers.cc }}</li>
              <li><strong>BCC:</strong> {{ parsed.headers.bcc }}</li>
              <li><strong>Reply-To:</strong> {{ parsed.headers["reply-to"] }}</li>
              <li><strong>In-Reply-To:</strong> {{ parsed.headers["in-reply-to"] }}</li>
              <li><strong>References:</strong> {{ parsed.headers.references }}</li>
            </ul>
          </div>
        </footer>
      </b-card>
    </div>
    <!-- 
    RRRR
    <b-card header-tag="header" footer-tag="footer" v-if="parsed">
      <template #header>
        <p class="title is-size-4">{{ parsed.headers.subject }}</p>
        <p class="subtitle is-size-6">{{ parsed.headers.from }}</p>
      </template>

      <template>
        <p>{{ parsed.text }}</p>
      </template>

      <template #footer>
        <div class="tags">
          <span class="tag">{{ parsed.headers.date }}</span>
          <span class="tag">{{ parsed.headers["message-id"] }}</span>
        </div>
        <b-button @click="showDetails = !showDetails">
          {{ showDetails ? "Hide details" : "View details" }}
        </b-button>
        <div v-if="showDetails">
          <h3 class="title is-size-5">HTML Version</h3>
          <div v-html="parsed.html"></div>
          <h3 class="title is-size-5">Additional Metadata</h3>
          <ul>
            <li><strong>To:</strong> {{ parsed.headers.to }}</li>
            <li><strong>CC:</strong> {{ parsed.headers.cc }}</li>
            <li><strong>BCC:</strong> {{ parsed.headers.bcc }}</li>
            <li><strong>Reply-To:</strong> {{ parsed.headers["reply-to"] }}</li>
            <li><strong>In-Reply-To:</strong> {{ parsed.headers["in-reply-to"] }}</li>
            <li><strong>References:</strong> {{ parsed.headers.references }}</li>
          </ul>
        </div>
      </template>
    </b-card>

    {{ email }} == email

      -->
  </div>
</template>

<script>
import Avatar from "vue-avatar-component";

export default {
  name: "MessageDetails",
  props: {
    /* email: {
      type: Object,
      required: true,
    },*/
  },
  components: {
    Avatar,
  },
  data() {
    return {
      showDetails: false,
      email: null,
    };
  },
  mounted() {
    this.getMessage();
  },
  beforeRouteUpdate(to, from, next) {
    this.getMessage();
    next();
  },

  methods: {
    //get messages from API
    async getMessage() {
      // alert(32466);
      window.API.me
        .getUserMsgs()
        .then((data) => {
          this.messages = data;
          //filter messages to find the one mathing the route param.
          this.email = this.messages.filter((message) => {
            console.log(message._id, this.$route.params.messageId, 5558874);
            return message._id === this.$route.params.messageId;
          })[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    parsed() {
      if (!this.email) return null;
      return this.email.parsed;
    },
  },
};
</script>

<style scoped>
.message-details {
  margin: 2rem;
}
</style>
